import React, { useCallback, useContext, useMemo } from "react";
import { InputText } from "@jobber/components/InputText";
import { Autocomplete, type Option } from "@jobber/components/Autocomplete";
import { GoogleApiWrapper } from "@jobber/google-maps-react-community";
import { useAddressAutocompleteResults } from "components/InputAddress/useAddressAutocompleteResults";
import type { AddressValues, LocationBias } from "./types";
import { AddressConfigurationContext } from "./AddressConfigurationContext";
import { useDeviceLocation } from "./useDeviceLocation";

type InputStreetProps = Omit<
  React.ComponentProps<typeof InputText> & { rows?: never },
  "value" | "onChange"
> & {
  address: AddressValues;
  onAddressChange(newAddress: AddressValues): void;
};

export function InputStreet({
  placeholder,
  address,
  onAddressChange,
  ...inputProps
}: InputStreetProps) {
  const { googleApiKey } = useContext(AddressConfigurationContext);

  const onFieldChanged = useCallback(
    (newValue: string) => onAddressChange({ ...address, street1: newValue }),
    [address, onAddressChange],
  );

  const renderBasicField = useCallback(
    () => (
      <InputText
        placeholder={placeholder}
        value={address.street1}
        onChange={onFieldChanged}
        {...inputProps}
      />
    ),
    [address.street1, inputProps, onFieldChanged, placeholder],
  );

  const onAutocompleteChanged = useCallback(
    (newAddress: Partial<AddressValues>) =>
      onAddressChange({ ...address, ...newAddress }),
    [address, onAddressChange],
  );

  const InputStreetAutocompleteWrapper = useMemo(
    () =>
      googleApiKey
        ? GoogleApiWrapper({
            apiKey: googleApiKey,
            LoadingContainer: renderBasicField,
          })(InputStreetAutocomplete)
        : () => <></>,
    /*
    Normally we have to include renderBasicField in the deps array, but it's
    intentionally left out to workaround the wrapper re-rendering when the
    street1 value changes. If it does, the Autocomplete field loses focus as the
    user is typing into the field.
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [googleApiKey],
  );

  return googleApiKey ? (
    <InputStreetAutocompleteWrapper
      placeholder={placeholder}
      value={address.street1}
      onChange={onAutocompleteChanged}
      name={inputProps.name}
      validations={inputProps.validations}
    />
  ) : (
    renderBasicField()
  );
}

export interface InputStreetAutocompleteProps {
  google: typeof google;
  placeholder?: string;
  value: AddressValues["street1"];
  onChange(newAddress: Partial<AddressValues>): void;
  name?: string;
  validations?: InputStreetProps["validations"];
}

export function circularLocationBias(bias?: LocationBias) {
  if (bias?.radius && bias?.center) {
    return new google.maps.Circle({
      radius: bias.radius,
      center: new google.maps.LatLng(bias.center[0], bias.center[1]),
    });
  }
}

function InputStreetAutocomplete({
  google,
  name,
  validations,
  placeholder,
  value,
  onChange,
}: InputStreetAutocompleteProps) {
  const { deviceLocation } = useDeviceLocation();
  const { locationBias: accountBias } = useContext(AddressConfigurationContext);

  /* istanbul ignore next -- @preserve */
  const locationBias = deviceLocation
    ? {
        radius: accountBias?.radius || 50_000,
        center: [deviceLocation.latitude, deviceLocation.longitude],
      }
    : accountBias;

  const { predictions, addressFromPlace } = useAddressAutocompleteResults({
    google,
    locationBias: circularLocationBias(locationBias),
  });

  const handleChange = useCallback(
    async (option?: Option) => {
      if (option) {
        if (option.value) {
          const address = await addressFromPlace(option.value.toString());
          onChange(address);
        } else {
          onChange({ street1: option.label ?? "" });
        }
      }
    },
    [onChange, addressFromPlace],
  );

  return (
    <Autocomplete
      allowFreeForm
      placeholder={placeholder ?? ""}
      value={{ label: value ?? "", value }}
      onChange={handleChange}
      getOptions={predictions}
      name={name}
      validations={validations}
    />
  );
}
