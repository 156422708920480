import React, { useCallback } from "react";
import type { IconNames } from "@jobber/design";
import { Autocomplete, type Option } from "@jobber/components/Autocomplete";
import type { RegisterOptions } from "~/utilities/reactHookForm/module";
import { useAddressAutocompleteResults } from "./useAddressAutocompleteResults";
import type { AddressValues } from "./types";

interface Props {
  allowFreeForm?: boolean;
  prefix?: { icon: IconNames };
  suffix?: { icon: IconNames };
  placeholder: string;
  value: string | undefined;
  name?: string | undefined;
  onAddressChange(address: AddressValues): void;
  validations?: RegisterOptions;
  placesService?: google.maps.places.PlacesService;
  autocompleteService?: google.maps.places.AutocompleteService;
}

export const AutocompleteAddress = ({
  value,
  onAddressChange,
  autocompleteService,
  placesService,
  ...rest
}: Props) => {
  const { predictions, addressFromPlace } = useAddressAutocompleteResults({
    google,
    autocompleteService,
    placesService,
  });

  const onChange = useCallback(
    async (option: Option | undefined) => {
      /* istanbul ignore if -- @preserve */
      if (!option) {
        return;
      }

      if (option.value) {
        const address = await addressFromPlace(option.value.toString(), [
          "formatted_address",
          "geometry",
        ]);

        onAddressChange(address);
      } else {
        onAddressChange({ formatted: option.label });
      }
    },
    [addressFromPlace, onAddressChange],
  );

  return (
    <Autocomplete
      value={{ value, label: value || "" }}
      getOptions={predictions}
      onChange={onChange}
      {...rest}
    />
  );
};
